import React from "react"
import axios from "axios"
import ReactLoading from "react-loading";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

function Hero(props){
  /* Koelinks bugs 27/11
  upper case domain name doesn't match
  terms of use
  privacy policy
  */
  return(
    <section className="w-full mx-auto bg-gray-700 flex h-auto rounded-lg shadow-lg">
      <div className="container mx-auto my-auto">
        <header className="flex flex-col w-full md:w-5/6 md:px-36 px-6 py-6 text-white">
          <h1 className="text-4xl font-bold tracking-wider mb-2">Link Analysis for Wordpress Sites</h1>
          <p className="tracking-wider">No plugin required</p>
        </header>
      </div>
    </section>
  )
}

function Table(props){
  return (
    <table className={props.addClasses + " table-fixed max-w-lg"}>
      {props.children}
    </table>
  )
}

function TableHead(props){
  return (
    <thead>
      {props.children}
    </thead>
  )
}

function TableH(props){
  return (
    <th className={props.addClasses + " px-3 py-2 title-font tracking-wider font-medium text-sm bg-gray-200"}>
      {props.children}
    </th>
  )
}

function TableRowHead(props) {
  return (
    <tr>
      {props.children}
    </tr>
  )
}

function TableBody(props) {
  return (
    <tbody>
      {props.children}
    </tbody>
  )
}

function TableRow(props) {
  return (
    <tr>
      {props.children}
    </tr>
  )
}

function TableData(props) {
  return (
    <td className={props.addClasses + " px-2 py-3 border-t-2 border-gray-200"}>
      {props.children}
    </td>
  )
}

class Tab extends React.Component {
  constructor(props) {
    super(props);
  }

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  }

  render() {
    const {
      onClick,
      props: {
        activeTab,
        label,
      },
    } = this;

    let classNameA = "block font-medium text-sm leading-tight uppercase"
    let classNameL = "min-w-fit cursor-pointer shadow-sm px-6 py-3 my-1 w-42 border-x-0 border-t-0 border-b-2 border-transparent hover:border-transparent hover:bg-gray-100 focus:border-transparent"
    
    if (activeTab === label) {
      classNameL += " bg-gray-100";
    }

    return (
      <li
        className={classNameL}
        onClick={onClick}
      >
        <a className={classNameA}>
          {label}
        </a>
      </li>
    );
  }
}

class Tabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  }

  render() {
    const {
      onClickTabItem,
      props: {
        children,
      },
      state: {
        activeTab,
      }
    } = this;

    return (
      <div className="flex items-start">
        <ol className="flex flex-col justify-start flex-wrap list-none border-b-0 bg-gray-50 min-h-screen pl-0 mt-3 mr-4">
          {children.map((child) => {
            const {label} = child.props;

            return (
              <Tab
                activeTab = {activeTab}
                key = {label}
                label = {label}
                onClick = {onClickTabItem}
              />
            );
          })}
        </ol>
        <div className="">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    )
  }
}

class NumberSelector extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(e) {
    this.props.handleChange(e.target.value)
  }

  render() {
    return (
      <input
        type="number"
        id="site-number"
        name={this.props.name}
        value={this.props.value}
        onChange={this.handleChange}
        className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-transparent focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
      />
    )
  }
}

class NameSelector extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.handleChange(e.target.value);
  }

  render() {
    return (
      <input
        type="text"
        id="site-name"
        name={this.props.name}
        value={this.props.value}
        onChange={this.handleChange}
        className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-transparent focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
      />
    )
  }
}

class LinkAnalyser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      domain: 'anybodysfan.com',
      oldDomain: '',
      domainLength: 0,
      numPosts: 5,
      rawPosts: [],
      posts: {},
      isLoading: false,
      isButtonDisabled: false,
      hasNoData: true,
      error: '',
      tabActive: 1
    }

    this.handleDomainChange = this.handleDomainChange.bind(this);
    this.handleNumPostsChange = this.handleNumPostsChange.bind(this);
    this.handleGo = this.handleGo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleDomainChange(v) {
    this.setState({domain: v.replace('https://','')});
  }

  handleNumPostsChange(v) {
    if(v > 100) {
      this.setState({numPosts: 100})
    } else {
      this.setState({numPosts: v})
    }
  }

  handleSubmit(e){
    e.preventDefault();
    this.handleGo();
  }

  handleGo() {
    let query = new URL(`https://${this.state.domain}/wp-json/wp/v2/posts?per_page=${this.state.numPosts}`)
    let headers = {}
    let dLength = this.state.domain.length
    this.setState({ isLoading: true })
    this.setState({isButtonDisabled: true})
    this.setState({domainLength: dLength})
    this.setState({oldDomain: this.state.domain})
    axios.get(query, headers)
      .then(res => {
        const rawPosts = res.data;
        this.setState( {rawPosts} );
        this.setState( {error: ''} )
        setTimeout(() => this.setState({ isButtonDisabled: false }), 1000+Math.floor(Math.random()*1000));
      })
      .catch(error => {
        this.setState({ isButtonDisabled: false })
        this.setState( {error: error.message} )
      })
      .finally( () => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    
    if(this.state.hasNoData){
      this.handleGo();
      this.setState({hasNoData: false})
    }
    
    console.log(this.state.domainLength);
    let reHref = new RegExp(/href=\".*?\"/,"g")
    let posts = {}
    for(const post of this.state.rawPosts){
      posts[post.link] = (posts[post.link] || {});
      posts[post.link]['internal'] = new Array(0);
      posts[post.link]['external'] = new Array(0);
      posts[post.link]['outboundURLs'] = new Array(0);
      if(post.content.rendered.toString().match(reHref)){
        for(const u of post.content.rendered.toString().match(reHref)){
          let ref='/';
          try {
            ref = new URL(u.substring(6, u.length-1).replace('www.',''))
            ref.protocol = 'https'
          } catch (error) {
            // add malformed links
          }
          posts[post.link].outboundURLs.push(ref);
        }
      } else {
        // posts with no links
      }
    }

    let inboundLinks = {}
    for(const post of Object.keys(posts)) {
      inboundLinks[post] = (inboundLinks[post] || {})
      inboundLinks[post].count = (inboundLinks[post].count || 0);

      for(const link of posts[post].outboundURLs){
        if(link.hostname === this.state.oldDomain){
          inboundLinks[link.href] = (inboundLinks[link.href] || {})
          inboundLinks[link.href].count = (inboundLinks[link.href].count || 0) + 1
        }
      }
    }

    let outboundLinksInternal = {}
    let outboundLinksExternal = {}
    for(const post of Object.keys(posts)){
      outboundLinksInternal[post] = (outboundLinksInternal[post] || {})
      outboundLinksInternal[post].count = (outboundLinksInternal[post].count || 0)
      outboundLinksExternal[post] = (outboundLinksExternal[post] || {})
      outboundLinksExternal[post].count = (outboundLinksExternal[post].count || 0)
      for(const link of posts[post].outboundURLs){
        if(link.hostname === this.state.oldDomain){
          outboundLinksInternal[post].count = (outboundLinksInternal[post].count || 0) + 1
        } else {
          outboundLinksExternal[post].count = (outboundLinksExternal[post].count || 0) + 1
        }
      }
    }
    return(
      <div>
        <div className="w-full h-auto justify-center items-center bg-white shadow-lg rounded-lg flex flex-col my-2">
          <form
            onSubmit={this.handleSubmit}
            className="flex lg:w-3/4 my-auto w-full sm:flex-row flex-col mx-auto px-8 py-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
            <div className="relative flex-grow w-full">
              <label htmlFor="site-name" className="leading-7 text-lg text-gray-600">Domain of Wordpress site</label>
              <NameSelector
                name="site-name"
                value={this.state.domain}
                handleChange={this.handleDomainChange}
              />
            </div>
            <div className="relative flex-grow w-full">
              <label htmlFor="site-number" className="leading-7 text-lg text-gray-600">Number of posts</label>
              <NumberSelector
                name="site-number"
                value={this.state.numPosts}
                handleChange={this.handleNumPostsChange}
              />
            </div>
            <button
              className={
                this.state.isButtonDisabled ? (
                  "text-white bg-gray-500 border-0 py-2 px-8 focus:outline-none hover:bg-gray-600 rounded text-lg"
                ) : (
                  "text-white bg-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-blue-600 rounded text-lg"
                )}
              onClick={this.handleGo}
              disabled={this.state.isButtonDisabled}
            >
                Go
            </button>
            <div>
              
              {this.state.isButtonDisabled || this.state.isLoading ? (
                  <ReactLoading type="bubbles" color="000000" />
                ) : (
                  <span></span>
                )
              }
            </div>
          </form>
        </div>

        {
        this.state.error ? (
          <div className="w-full h-auto justify-center items-center bg-white shadow-lg rounded-lg flex flex-col my-2">
            <div className="flex lg:w-3/4 w-full sm:flex-row flex-col mx-auto px-8 py-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
              <p>{this.state.error}</p>
              <p>Couldn't read data from domain</p>
            </div>
          </div>
        ) : (<div></div>)
        }

        <div className="w-full min-h-screen h-auto bg-white shadow-lg rounded-lg flex flex-col my-2">
          <div className="md:px-36 py-8 max-w-6xl">
            <Tabs>
              <div label="Posts Found" className="max-w-xl">
                <p className="py-3 leading-7 text-lg text-gray-600 max-w-lg">Found {this.state.numPosts} posts. Count of how much each post links to {this.state.domain}:</p>
                <Table addClasses={this.state.isButtonDisabled ? "hidden" : ""}>
                  <TableHead>
                    <TableRowHead>
                      <TableH addClasses="rounded-tl rounded-bl">Post</TableH>
                      <TableH addClasses="rounded-tr rounded-br text-center">Links from this post</TableH>
                    </TableRowHead>
                  </TableHead>
                  <TableBody>
                    {
                      Object.keys(outboundLinksInternal).sort(
                        function(a,b) {return (outboundLinksInternal[a].count || 0) - (outboundLinksInternal[b].count || 0)}
                      ).map(post =>
                        <TableRow>
                          <TableData addClasses="text-left">
                            <a href={post}>{post.replace('https://','').substring(this.state.domainLength+1, post.length-1)}</a>
                          </TableData>
                          <TableData addClasses="text-center">
                            {outboundLinksInternal[post].count || 0}
                          </TableData>
                        </TableRow>
                      )
                    }
                  </TableBody>
                </Table>
              </div>
              <div label="Interlinking Analysis" className="max-w-lg">
                <p className="py-3 leading-7 text-lg text-gray-600 max-w-lg">Posts found based on the number of links <em>to</em> the post. Improve your inter-linking by adding more links to these posts:</p>
                <Table addClasses={this.state.isButtonDisabled ? "hidden" : ""}>
                  <TableHead>
                    <TableRowHead>
                      <TableH addClasses="rounded-tl rounded-bl">Post</TableH>
                      <TableH addClasses="rounded-tr rounded-br text-center">Links to this post</TableH>
                    </TableRowHead>
                  </TableHead>
                  <TableBody>
                    {
                      Object.keys(inboundLinks).sort(
                        function(a,b) {return (inboundLinks[a].count || 0) - (inboundLinks[b].count || 0)}
                      ).map(post =>
                        <TableRow>
                          <TableData addClasses="text-left">
                            <a href={post}>{post.replace('https://','').replace(this.state.oldDomain+'/','')}</a>
                          </TableData>
                          <TableData addClasses="text-center">
                            {inboundLinks[post].count || 0}
                          </TableData>
                        </TableRow>
                      )
                    }
                  </TableBody>
                </Table>
              </div>
              <div label="Instructions">
                <div className="container my-auto max-w-lg leading-relaxed text-lg text-gray-600">
                  <p className="py-3">
                    To analyse your inter-linking: Enter the domain of a Wordpress site and hit go.
                  </p>
                  <p className="py-2">
                    Number of posts is the number of most recent posts we will look back over to analyse the linking on your website.
                  </p>
                  <p className="py-2">
                    Only links within posts are counted. Links from pages and menus are not counted when analysing inter-linking.
                  </p>
                  <p className="py-2">
                    If the link count doesn't update after you edit your posts, it may be due to a cache tool. Sometimes cache services,
                    such as Ezoic speed tools, send an older version of your website to Koelinks. Clear the cache on the cache tool 
                    to force an update.
                  </p>
                  <p className="py-2">
                    More questions about inter-linking? Check out the detailed FAQ <Link to="/">here</Link>
                  </p>
                </div>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    )
    /*
    return(
      <div>
        <div className="w-full h-auto justify-center items-center bg-white shadow-lg rounded-lg flex flex-col my-2">
          <form
            onSubmit={this.handleSubmit}
            className="flex lg:w-2/3 my-auto w-full sm:flex-row flex-col mx-auto px-8 py-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
            <div className="relative flex-grow w-full">
              <label htmlFor="site-name" className="leading-7 text-sm text-gray-600">Domain of Wordpress site</label>
              <NameSelector
                name="site-name"
                value={this.state.domain}
                handleChange={this.handleDomainChange}
              />
            </div>
            <div className="relative flex-grow w-full">
              <label htmlFor="site-number" className="leading-7 text-sm text-gray-600">Number of posts</label>
              <NumberSelector
                name="site-number"
                value={this.state.numPosts}
                handleChange={this.handleNumPostsChange}
              />
            </div>
            <button
              className={
                this.state.isButtonDisabled ? (
                  "text-white bg-gray-500 border-0 py-2 px-8 focus:outline-none hover:bg-gray-600 rounded text-lg"
                ) : (
                  "text-white bg-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-blue-600 rounded text-lg"
                )}
              onClick={this.handleGo}
              disabled={this.state.isButtonDisabled}
            >
                Go
            </button>
            <div>
              
              {this.state.isButtonDisabled || this.state.isLoading ? (
                  <ReactLoading type="bubbles" color="000000" />
                ) : (
                  <span></span>
                )
              }
            </div>
          </form>
        </div>

        {
        this.state.error ? (
          <div className="w-full h-auto justify-center items-center bg-white shadow-lg rounded-lg flex flex-col my-2">
            <div className="flex lg:w-2/3 w-full sm:flex-row flex-col mx-auto px-8 py-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
              <p>{this.state.error}</p>
              <p>Couldn't read data from domain</p>
            </div>
          </div>
        ) : (<div></div>)
        }

        <div className="w-full h-auto justify-center bg-white shadow-lg rounded-lg flex md:flex-row flex-col my-2">
          <div className="h-full md:w-5/12 flex-1 bg-gray-100 bg-opacity-75 px-8 pt-4 pb-4 my-4 ml-4 mr-2 rounded-lg overflow-hidden text-center relative">
            <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-2 px-2">Improve by adding links TO these posts (inbound)</h2>
            <Table>
              <TableHead>
                <TableRowHead>
                  <TableH addClasses="rounded-tl rounded-bl">Post</TableH>
                  <TableH addClasses="rounded-tr rounded-br">Count</TableH>
                </TableRowHead>
              </TableHead>
              <TableBody>
                {
                  Object.keys(inboundLinks).sort(
                    function(a,b) {return (inboundLinks[a].count || 0) - (inboundLinks[b].count || 0)}
                  ).map(post =>
                    <TableRow>
                      <TableData addClasses="text-left">
                        <a href={post}>{post.replace('https://','').replace(this.state.oldDomain+'/','')}</a>
                      </TableData>
                      <TableData>
                        {inboundLinks[post].count || 0}
                      </TableData>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </div>
          <div className="h-full md:w-5/12 w-full flex-1 bg-gray-100 bg-opacity-75 px-8 pt-4 pb-4 my-4 ml-2 mr-4 rounded-lg overflow-hidden text-center relative">
            <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-2 px-2">Improve by adding links FROM these posts (outbound)</h2>
            <Table>
              <TableHead>
                <TableRowHead>
                  <TableH addClasses="rounded-tl rounded-bl">Post</TableH>
                  <TableH addClasses="rounded-tr rounded-br">Count</TableH>
                </TableRowHead>
              </TableHead>
              <TableBody>
                {
                  Object.keys(outboundLinksInternal).sort(
                    function(a,b) {return (outboundLinksInternal[a].count || 0) - (outboundLinksInternal[b].count || 0)}
                  ).map(post =>
                    <TableRow>
                      <TableData addClasses="text-left">
                        <a href={post}>{post.replace('https://','').substring(this.state.domainLength+1, post.length-1)}</a>
                      </TableData>
                      <TableData>
                        {outboundLinksInternal[post].count || 0}
                      </TableData>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </div>
        </div>
        </div>
    )
    */
  }
}

const LinksPage = () => (
  <Layout>
    <SEO title="Link Analysis" />
    <Hero />
    <div className="container mx-auto"><LinkAnalyser/></div>
  </Layout>
)

export default LinksPage
